import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  if (window.locations[window.locations.length - 1] !== window.location.href) {
    window.locations.push(window.location.href)
  }
  window.previousPath = window.locations[window.locations.length - 2]
}
